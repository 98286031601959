
body {
    font-family: 'Nunito';
    background-color: #141414;
}

#bg > div {
    background-position: right;
}

html {
    font-family:'Nunito';
}

input {
    font-family: 'Nunito' !important;
}

input::placeholder {
    font-family: 'Nunito' !important;
}
  
.swiper-pagination-bullet {
    width: 100px !important;
    opacity: 0.8 !important;
    background-color: gray !important;
    border-radius: .5rem !important;
    cursor: pointer;
    height: 5px !important;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
}