@import url(https://fonts.googleapis.com/css2?family=Carrois+Gothic&family=Pinyon+Script&display=swap);
body {
	background-color: #141414;
	font-family: "Nunito", sans-serif;
}

[disabled] {
	cursor: default !important;
  }
 

html {
	margin: 0;
	font-family: "Nunito";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Nunito", sans-serif;
}

input {
	font-family: "Nunito", sans-serif !important;
}

input::-webkit-input-placeholder {
	font-family: "Nunito", sans-serif !important;
}

input::placeholder {
	font-family: "Nunito", sans-serif !important;
}

input:-internal-autofill-selected {
	height: 20px;
}

/* Remove autocomplete styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #fff !important;
	-webkit-transition: background-color 600000s 0s, color 600000s 0s !important;
	transition: background-color 600000s 0s, color 600000s 0s !important;
}

#root > :nth-child(1) {
	height: 100%;
	width: 100%;
	background-color: transparent !important;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	display: none;
}

body::-webkit-scrollbar-thumb {
	background: white;
	border-radius: 5px;
	cursor: pointer;
	visibility: hidden;
}

::-webkit-scrollbar-track {
	background: transparent;
}

.floating-label-content {
	position: relative;
}

.floating-label {
	font-size: 25px;
	font-family: "Nunito", sans-serif;
	position: absolute;
	pointer-events: none;
	color: #f2f2f2;
	left: 15px;
	top: 17px;
	width: 235px;
}

a{
	color: #F2F2F2 !important;
	text-decoration: none;
}

#root {
	cursor: default;
}

body {
    font-family: 'Nunito';
    background-color: #141414;
}

#bg > div {
    background-position: right;
}

html {
    font-family:'Nunito';
}

input {
    font-family: 'Nunito' !important;
}

input::-webkit-input-placeholder {
    font-family: 'Nunito' !important;
}

input::placeholder {
    font-family: 'Nunito' !important;
}
  
.swiper-pagination-bullet {
    width: 100px !important;
    opacity: 0.8 !important;
    background-color: gray !important;
    border-radius: .5rem !important;
    cursor: pointer;
    height: 5px !important;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
}
.bcore-theme .d-none {
	display: none !important;
}

.bcore-theme button:hover {
	background-color: transparent;
}

.bcore-theme .bcore-setting-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #191919;
	z-index: 10000;
	transition: 0.3s -webkit-transform ease-out;
	transition: 0.3s transform ease-out;
	transition: 0.3s transform ease-out, 0.3s -webkit-transform ease-out;
	-webkit-transform: translateY(100%);
	        transform: translateY(100%);
	color: white;
	display: flex;
	flex-direction: column;
}

.bcore-theme .bcore-setting-menu.show-menu {
	display: block;
	margin-right: 20px;
}

.bcore-theme .bcore-setting-menu{
	margin-right: 20px;
}

.bcore-theme .bcore-setting-container.show {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}

.bcore-theme .bcore-setting-header-container {
    background-color: #000;
    width: 100%;
    height: 112px;
    flex-direction: row;
    display: flex;
}


.bcore-theme .bcore-setting-container .bcore-settings-title {
	font-size: 50px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Nunito", Arial, sans-serif;
}

/* width */
.bcore-theme ::-webkit-scrollbar {
	width: 10px;
}

/* Track */
.bcore-theme ::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
.bcore-theme ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 2px;
}

/* Handle on hover */
.bcore-theme ::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus {
	display: flex;
	width: 100%;
	margin-top: 50px;
	margin-left: -20px;
	flex: 0 1 auto;
	overflow: hidden;
	height: 100%;
	padding: 0 100px 0 100px;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .option-text {
	display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Nunito", Arial, sans-serif;
}

.bcore-theme .bcore-setting-header-container .bcore-button {
	width: 122px;
}

.bcore-theme .bcore-setting-header-container .bcore-button#settings-back-button {
	width: 120px;
}

.bcore-theme .shaka-scrim-container .bcore-collapse-icon {
	height: 112px;
    width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bcore-theme .shaka-scrim-container .bcore-expand-icon {
	height: 112px;
    width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bcore-theme.right .bcore-setting-header-container .bcore-button#settings-back-button {
	width: 122px !important;
}

.bcore-theme.right .bcore-setting-header-container .bcore-settings-title {
	flex: 1 1;
	margin-left: 92px;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-menu {
	width: 30%;
	padding: 0 20px 20px 0;
	display: none;
	overflow: auto;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-menu.show,
.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-menu.menu-1 {
	display: block;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	background: none;
	border: none;
	cursor: pointer;
	padding: 10px 20px;
	margin-bottom: 24px;
	width: 100%;
	text-align: left;
	font-size: 24px;
	height: 72px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    background-color: #182B47;
    border-radius: 16px;
	color: white;
	text-transform: capitalize;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.selection {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	background: none;
	border: none;
	cursor: pointer;
	padding: 10px 20px;
	margin-bottom: 24px;
	width: 100%;
	text-align: left;
	font-size: 24px;
	height: 72px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    background-color: transparent;
    border-radius: 16px;
	color: white;
	text-transform: capitalize;
	opacity: 0.6;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.selection.active {
    background-color: transparent;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.selection:hover {
	opacity: 1;
    background-color: transparent;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.selection svg {
	padding-right: 16px;
    opacity: 0;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.selection.active svg {
    opacity: 1;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button #chevron-right {
	opacity: 0;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.active #chevron-right {
	opacity: 1;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.sub-menu {
    background-color: rgba(255, 255, 255, 0.15);
	opacity: 0.5;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.active {
	background-color: #2362be;
	opacity: 1;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.inactive {
	background-color: #182B47;
	opacity: 0.3;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button:hover {
	background-color: #2362be;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.sub-menu.active {
	background-color: rgba(255, 255, 255, 0.15);
	opacity: 1;
}
  
.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.sub-menu.inactive {
    background-color: rgba(255, 255, 255, 0.15);
	opacity: 0.5;
}

.bcore-theme .bcore-setting-container .bcore-setting-menus .bcore-setting-button.sub-menu:hover {
	background-color: rgba(255, 255, 255, 0.15);
	opacity: 1;
}

.bcore-theme .bcore-settings-preview-container {
	flex-grow: 1;
	margin-top: 10px;
	padding: 0 80px 50px 80px;
}

.bcore-theme .bcore-settings-preview-container .bcore-preview-text {
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.bcore-theme .bcore-settings-preview-container .bcore-preview {
	background: #333333;
	height: 3rem;
	width: 100%;
	border-radius: 15px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bcore-theme #bcore-playtime,
.bcore-theme #bcore-duration {
	cursor: default;
	position: absolute;
	padding: 0;
	top: 5px;
	font-size: 16px;
	height: 16px;
}

.bcore-theme #bcore-playtime {
	left: 0px;
}

.bcore-theme #bcore-duration {
	right: 0px;
	text-align: right;
}

.bcore-theme #bcore-text-container {
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0;
	justify-content: center;
	color: white;
	display: flex;
	flex-direction: column;
	margin-right: 122px;
}

.bcore-theme #bcore-text-container #bcore-title {
	font-size: 50px;
	height: 69px;
	font-family: "Nunito", Arial, sans-serif;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	z-index: 999;
}

.bcore-theme #bcore-text-container #bcore-rating {
	height: 24px;
	opacity: 0.8;
	font-family: "Nunito", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.11;
	letter-spacing: 0.14px;
	text-align: left;
	z-index: 999;
	width: 90%;
}

.bcore-theme #bcore-settings-button {
	width: 75px;
	height: 60px;
	position: absolute;
	top: 10px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	cursor: pointer;
}

.bcore-theme #bcore-settings-button:focus {
	box-shadow: none;
	-webkit-box-shadow: none;
}

.bcore-theme #bcore-settings-button svg {
	width: 60px;
	height: 60px;
	cursor: pointer;
	overflow: visible;
}

.bcore-theme .shaka-controls-button-panel .hover-hide,
.bcore-theme .shaka-controls-button-panel *:hover .hover-show {
	display: block;
}

.bcore-theme .shaka-controls-button-panel *:hover .hover-hide,
.bcore-theme .shaka-controls-button-panel .hover-show {
	display: none;
}


.bcore-theme .shaka-controls-container .hover-hide,
.bcore-theme .shaka-controls-container *:hover .hover-show {
	display: block;
}

.bcore-theme .shaka-controls-container *:hover .hover-hide,
.bcore-theme .shaka-controls-container .hover-show {
	display: none;
}

.bcore-theme .hide-button {
	display: none !important;
}

.bcore-theme .bcore-fullscreen .hide-icon {
	display: none !important;
}


.bcore-theme {
	--black: 0, 0, 0;
	--white: 255, 255, 255;
	--red: 255, 0, 0;
	--purple: 128, 0, 128;
	--blue: 0, 0, 255;
	--green: 0, 128, 0;
	--yellow: 255, 255, 0;
	--orange: 255, 165, 0;
	--magenta: 255, 0, 255;
	--cyan: 0, 255, 255;
	font-family: "Roboto", sans-serif;
	overflow: hidden;
	position: relative;
}

.bcore-theme .shaka-scrim-container {
	padding: 0px 122px 122px 0;
	height: 122px;
	width: 100%;
	display: flex;
	flex-basis: auto;
	flex-shrink: 0;
	flex-direction: row;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0));
}

input[type="range"]::-webkit-slider-runnable-track {
	height: 8px;
	border-radius: 4px;
}

input[type="range"]::-webkit-slider-thumb {
	margin-top: -1px;
	height: 1rem;
	width: 1rem;
}

.shaka-small-play-button {
	opacity: 0;
}

.bcore-theme #bcore-back-button,
.bcore-theme #bcore-fullscreen,
.bcore-theme #bcore-close-button {
	height: 112px;
	width: 120px;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	
}


.bcore-theme #bcore-back-button{
	width: 122px;
}

.bcore-theme.right #bcore-back-button{
	width: 122px;
}

.bcore-theme #bcore-playpause #bcore-play,
.bcore-theme #bcore-playpause #bcore-pause {
	width: 96px;
	height: 96px;
	opacity: 0.5;
	border-radius: 100%;
	background-color: rgb(0, 0, 0);
	cursor: pointer;
}

.bcore-theme #bcore-playpause #bcore-play:hover,
.bcore-theme #bcore-playpause #bcore-pause:hover {
	width: 96px;
	height: 96px;
	opacity: 0.8;
	border-radius: 100%;
	background-color: rgb(0, 0, 0);
	cursor: pointer;
}

.bcore-theme .bcore-watch-now {
	position: absolute;
	left: 122px;
	bottom: 122px;
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	z-index: 9999;
}

.bcore-theme .bcore-watch-now .watch-now-button {
	display: flex;
	flex-direction: row;
	opacity: 0.8;
}

.bcore-theme .bcore-watch-now .watch-now-button:hover {
	opacity: 1;
}

.bcore-theme .bcore-watch-now .watch-now-title {
	display: flex;
	flex-direction: column;
}

.bcore-theme .bcore-watch-now .watch-skip-title {
	display: flex;
	justify-content: center;
	height: 80px;
	align-items: center;
	font-family: "Nunito", Arial, sans-serif;
	font-size: 37px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: whitesmoke;
}


.bcore-theme .bcore-watch-now .watch-now-title .pre-title {
	margin: 4px 8px 2px 6px;
	opacity: 0.8;
	font-family: "Nunito", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.11;
	letter-spacing: 0.14px;
	text-align: left;
	color: whitesmoke;
}

.bcore-theme .bcore-watch-now .watch-now-title .movie-title {
	margin: 4px 0 0 6px;
	font-family: "Nunito", Arial, sans-serif;
	font-size: 37px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: whitesmoke;
}

.bcore-theme .bcore-watch-now .watch-now-title .movie-title-container {
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bcore-theme .bcore-watch-now .watch-now-button .watch-now-icon {
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bcore-theme .bcore-bg-left {
	position: absolute;
	left: 0px;
	width: 122px;
	height: 100vh;
	padding-right: 122px;
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0));
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	display: flex;
	flex-direction: column;
}

.bcore-theme.zoom .bcore-bg-left {
	position: absolute;
	left: 0px;
	width: 122px;
	height: calc(100vh + calc(100vh * 0.6));
	padding-right: 122px;
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0));
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	display: flex;
	flex-direction: column;
}

.bcore-theme #bcore-side-buttons-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 1;
}

.bcore-theme #bcore-side-buttons-container .side-button {
	width: 48px;
	height: 48px;
	opacity: 0.5;
	padding: 4px;
	margin-bottom: 28px;
	border-radius: 100%;
	background-color: black;
}

.bcore-theme #bcore-side-buttons-container .side-button:hover {
	opacity: 0.8;
	background-color: black;
}

.bcore-theme .bcore-bg-right {
	position: absolute;
	right: 122px;
	width: 122px;
	height: 100vh;
	padding-left: 122px;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0));
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	display: flex;
	flex-direction: column;
}

.bcore-theme.zoom .bcore-bg-right {
	position: absolute;
	right: 122px;
	width: 122px;
	height: calc(100vh + calc(100vh * 0.6));
	padding-left: 122px;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0));
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	display: flex;
	flex-direction: column;
}

.bcore-theme .shaka-controls-container[shown="true"] .shaka-bottom-controls,
.bcore-theme .shaka-controls-container[shown="true"] .bcore-bg-left,
.bcore-theme .shaka-controls-container[shown="true"] .bcore-bg-right,
.bcore-theme .shaka-controls-container[shown="true"] .bcore-watch-now {
	opacity: 1;
}

.bcore-theme .shaka-bottom-controls {
	width: 100%;
	height: 122px;
	padding: 0px 0px 0px 0px;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0));
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	z-index: 998;
}

.bcore-theme .shaka-ad-controls {
	order: 1;
}

.bcore-theme .shaka-controls-button-panel {
	position: relative;
	order: 1;
	height: 30px;
	margin: 0px 5vw;
	overflow: visible;
}

.bcore-theme .shaka-range-container.shaka-seek-bar-container {
	order: 2;
	margin: 0px 5vw 60px;
	padding: 0 10px;
	border-radius: 4px;
	top: 0;
	z-index: 9999;
}

.bcore-theme .shaka-controls-container[shown="true"] {
	cursor: pointer;
}

.bcore-theme .shaka-controls-container #bcore-playpause {
	order: -3;
	-webkit-transform: translateY(-20%);
	        transform: translateY(-20%);
	position: absolute;
	top: calc(50% - 64px);
	opacity: 0;
	transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
	z-index: 9999;
}

.bcore-theme .shaka-controls-container[shown="true"] #bcore-playpause {
	opacity: 1;
}

.bcore-theme .shaka-controls-container .bcore-button {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.bcore-theme .shaka-mute-button {
	order: -2;
	-webkit-transform: translateY(15px);
	        transform: translateY(15px);
}

.bcore-theme .shaka-controls-button-panel > * {
	margin: 0;
	padding: 3px 8px;
	color: #fff;
	height: 60px;
	width: 60px;
}

.bcore-theme .shaka-controls-button-panel > *:focus {
	outline: none;
	color: #fff;
}

.bcore-theme .shaka-controls-button-panel .shaka-volume-bar-container {
	position: relative;
	z-index: 10;
	left: -1px;
	order: -1;
	opacity: 1;
	width: 75px;
	height: 3px;
	padding: 0 6px;
	margin-top: 10px;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
	cursor: pointer;
}

.bcore-theme .shaka-controls-button-panel .shaka-volume-bar-container .shaka-volume-bar {
	cursor: pointer;
}

.bcore-theme .shaka-seek-bar-container {
	height: 8px;
	position: relative;
	top: -1px;
	border-radius: 4px;
	margin-bottom: 0;
}

.bcore-theme .shaka-seek-bar-container .shaka-range-element {
	opacity: 0;
}

.bcore-theme .shaka-seek-bar-container:hover {
	height: 8px;
	border-radius: 4px;
	top: 0;
	cursor: pointer;
}

.bcore-theme .shaka-seek-bar-container:hover .shaka-range-element {
	opacity: 1;
	cursor: pointer;
}

.bcore-theme .shaka-seek-bar-container input[type="range"]::-webkit-slider-thumb {
	background: #fff;
	cursor: pointer;
}

.bcore-theme .shaka-seek-bar-container input[type="range"]::-moz-range-thumb {
	background: #fff;
	cursor: pointer;
}

.bcore-theme .shaka-seek-bar-container input[type="range"]::-ms-thumb {
	background: #fff;
	cursor: pointer;
}

.bcore-theme .shaka-video-container * {
	font-family: "Roboto", sans-serif;
}

.bcore-theme .shaka-video-container .material-icons-round {
	font-family: "Material Icons Sharp", sans-serif;
	font-size: 42px;
}

.bcore-theme .shaka-video-container .material-icons-round.shaka-mute-button {
	font-size: 24px;
}

.bcore-theme .shaka-fullscreen-button {
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.bcore-theme .shaka-fullscreen-button:hover {
	font-size: 25px;
	transition: font-size 0.1s cubic-bezier(0, 0, 0.2, 1);
}

.bcore-theme .shaka-play-button-container {
	display: none;
}

@media (max-width: 732px) {
	.bcore-theme .shaka-mute-button {
		margin-left: -50px;
	}
}

@media (min-width: 765px) {
	.bcore-theme .shaka-bottom-controls {
		width: 92%;
	}
}

@media (min-width: 992px) {
	.bcore-theme .shaka-bottom-controls {
		width: 93vw;
	}
}
@media (min-width: 1400px) {
	.bcore-theme .shaka-bottom-controls {
		width: 95vw;
	}
}
@media (min-width: 1600px) {
	.bcore-theme .shaka-bottom-controls {
		width: 96vw;
	}
}
@media (min-width: 1900px) {
	.bcore-theme .shaka-bottom-controls {
		width: 98vw;
	}
}

.bcore-theme #seek-preview {
	position: absolute;
	background-repeat: no-repeat;
	height: 300px;
	width: 534px;
	display: none;
	border: 2px solid rgba(252, 252, 252, 0.3);
	border-radius: 8px;
}

.bcore-theme .seek-preview-time {
	margin-top: 310px;
	display: none;
	font-size: 18px;
	line-height: 20px;
	height: 80px;
	width: 534px;
	text-align: center;
	color: rgba(242, 242, 242, 1);
}

/* Subtitle Style Classes */
.bcore-theme #bcore-preview-demo span,
.bcore-theme .shaka-text-container span {
	padding: 5px 10px;
	border: 2px solid transparent;
}

/* Text Color */
.bcore-theme .text-color-black span {
	color: rgb(var(--black)) !important;
}
.bcore-theme .text-color-black.text-opacity-025 span {
	color: rgba(var(--black), 0.25) !important;
}
.bcore-theme .text-color-black.text-opacity-050 span {
	color: rgba(var(--black), 0.5) !important;
}
.bcore-theme .text-color-black.text-opacity-075 span {
	color: rgba(var(--black), 0.75) !important;
}
.bcore-theme .text-color-black.text-opacity-100 span {
	color: rgba(var(--black), 1) !important;
}

.bcore-theme .text-color-white span {
	color: rgb(var(--white)) !important;
}
.bcore-theme .text-color-white.text-opacity-025 span {
	color: rgba(var(--white), 0.25) !important;
}
.bcore-theme .text-color-white.text-opacity-050 span {
	color: rgba(var(--white), 0.5) !important;
}
.bcore-theme .text-color-white.text-opacity-075 span {
	color: rgba(var(--white), 0.75) !important;
}
.bcore-theme .text-color-white.text-opacity-100 span {
	color: rgba(var(--white), 1) !important;
}

.bcore-theme .text-color-red span {
	color: rgb(var(--red)) !important;
}
.bcore-theme .text-color-red.text-opacity-025 span {
	color: rgba(var(--red), 0.25) !important;
}
.bcore-theme .text-color-red.text-opacity-050 span {
	color: rgba(var(--red), 0.5) !important;
}
.bcore-theme .text-color-red.text-opacity-075 span {
	color: rgba(var(--red), 0.75) !important;
}
.bcore-theme .text-color-red.text-opacity-100 span {
	color: rgba(var(--red), 1) !important;
}

.bcore-theme .text-color-purple span {
	color: rgb(var(--purple)) !important;
}
.bcore-theme .text-color-purple.text-opacity-025 span {
	color: rgba(var(--purple), 0.25) !important;
}
.bcore-theme .text-color-purple.text-opacity-050 span {
	color: rgba(var(--purple), 0.5) !important;
}
.bcore-theme .text-color-purple.text-opacity-075 span {
	color: rgba(var(--purple), 0.75) !important;
}
.bcore-theme .text-color-purple.text-opacity-100 span {
	color: rgba(var(--purple), 1) !important;
}

.bcore-theme .text-color-blue span {
	color: rgb(var(--blue)) !important;
}
.bcore-theme .text-color-blue.text-opacity-025 span {
	color: rgba(var(--blue), 0.25) !important;
}
.bcore-theme .text-color-blue.text-opacity-050 span {
	color: rgba(var(--blue), 0.5) !important;
}
.bcore-theme .text-color-blue.text-opacity-075 span {
	color: rgba(var(--blue), 0.75) !important;
}
.bcore-theme .text-color-blue.text-opacity-100 span {
	color: rgba(var(--blue), 1) !important;
}

.bcore-theme .text-color-green span {
	color: rgb(var(--green)) !important;
}
.bcore-theme .text-color-green.text-opacity-025 span {
	color: rgba(var(--green), 0.25) !important;
}
.bcore-theme .text-color-green.text-opacity-050 span {
	color: rgba(var(--green), 0.5) !important;
}
.bcore-theme .text-color-green.text-opacity-075 span {
	color: rgba(var(--green), 0.75) !important;
}
.bcore-theme .text-color-green.text-opacity-100 span {
	color: rgba(var(--green), 1) !important;
}

.bcore-theme .text-color-yellow span {
	color: rgb(var(--yellow)) !important;
}
.bcore-theme .text-color-yellow.text-opacity-025 span {
	color: rgba(var(--yellow), 0.25) !important;
}
.bcore-theme .text-color-yellow.text-opacity-050 span {
	color: rgba(var(--yellow), 0.5) !important;
}
.bcore-theme .text-color-yellow.text-opacity-075 span {
	color: rgba(var(--yellow), 0.75) !important;
}
.bcore-theme .text-color-yellow.text-opacity-100 span {
	color: rgba(var(--yellow), 1) !important;
}

.bcore-theme .text-color-orange span {
	color: rgb(var(--orange)) !important;
}
.bcore-theme .text-color-orange.text-opacity-025 span {
	color: rgba(var(--orange), 0.25) !important;
}
.bcore-theme .text-color-orange.text-opacity-050 span {
	color: rgba(var(--orange), 0.5) !important;
}
.bcore-theme .text-color-orange.text-opacity-075 span {
	color: rgba(var(--orange), 0.75) !important;
}
.bcore-theme .text-color-orange.text-opacity-100 span {
	color: rgba(var(--orange), 1) !important;
}

.bcore-theme .text-color-magenta span {
	color: rgb(var(--magenta)) !important;
}
.bcore-theme .text-color-magenta.text-opacity-025 span {
	color: rgba(var(--magenta), 0.25) !important;
}
.bcore-theme .text-color-magenta.text-opacity-050 span {
	color: rgba(var(--magenta), 0.5) !important;
}
.bcore-theme .text-color-magenta.text-opacity-075 span {
	color: rgba(var(--magenta), 0.75) !important;
}
.bcore-theme .text-color-magenta.text-opacity-100 span {
	color: rgba(var(--magenta), 1) !important;
}

.bcore-theme .text-color-cyan span {
	color: rgb(var(--cyan)) !important;
}
.bcore-theme .text-color-cyan.text-opacity-025 span {
	color: rgba(var(--cyan), 0.25) !important;
}
.bcore-theme .text-color-cyan.text-opacity-050 span {
	color: rgba(var(--cyan), 0.5) !important;
}
.bcore-theme .text-color-cyan.text-opacity-075 span {
	color: rgba(var(--cyan), 0.75) !important;
}
.bcore-theme .text-color-cyan.text-opacity-100 span {
	color: rgba(var(--cyan), 1) !important;
}

/* Text Size */
.bcore-theme .text-size-14 span {
	font-size: 14px !important;
}
.bcore-theme .text-size-16 span {
	font-size: 16px !important;
}
.bcore-theme .text-size-18 span {
	font-size: 18px !important;
}
.bcore-theme .text-size-20 span {
	font-size: 20px !important;
}
.bcore-theme .text-size-22 span {
	font-size: 22px !important;
}
.bcore-theme .text-size-24 span {
	font-size: 24px !important;
}
.bcore-theme .text-size-26 span {
	font-size: 26px !important;
}
.bcore-theme .text-size-28 span {
	font-size: 28px !important;
}
.bcore-theme .text-size-30 span {
	font-size: 30px !important;
}
.bcore-theme .text-size-32 span {
	font-size: 32px !important;
}
.bcore-theme .text-size-34 span {
	font-size: 34px !important;
}
.bcore-theme .text-size-36 span {
	font-size: 36px !important;
}
.bcore-theme .text-size-38 span {
	font-size: 38px !important;
}
.bcore-theme .text-size-40 span {
	font-size: 40px !important;
}
.bcore-theme .text-size-42 span {
	font-size: 42px !important;
}

/* Text Edge Style */
.bcore-theme .edge-style-drop-none span {
	text-shadow: none !important;
}

.bcore-theme .edge-style-drop-shadow span {
	text-shadow: 2px 2px 2px #000000 !important;
}
.bcore-theme .edge-style-raised span {
	text-shadow: 0 -1px 0 #fff, 0 1px 0 #000 !important;
}
.bcore-theme .edge-style-depressed span {
	text-shadow: 0 -1px 0 #000, 0 1px 0 #fff !important;
}
.bcore-theme .edge-style-uniform span {
	text-shadow: -1px 1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000 !important;
}

/* Font Family */
.bcore-theme .font-family-comic span {
	font-family: "Comic Sans MS", sans-serif !important;
}
.bcore-theme .font-family-times span {
	font-family: "Times New Roman", sans-serif !important;
}
.bcore-theme .font-family-arial span {
	font-family: Arial, sans-serif !important;
}
.bcore-theme .font-family-sans span {
	font-family: "SansSerif", sans-serif !important;
}
.bcore-theme .font-family-courier span {
	font-family: "Courier New", sans-serif !important;
}
.bcore-theme .font-family-roboto span {
	font-family: "Roboto", sans-serif !important;
}
.bcore-theme .font-family-roboto-mono span {
	font-family: "Roboto Mono", monospace !important;
}
.bcore-theme .font-family-carrois-gothic span {
	font-family: "Carrois Gothic", sans-serif !important;
}
.bcore-theme .font-family-pinyon-script span {
	font-family: "Pinyon Script", sans-serif !important;
}

/* Background Color */
.bcore-theme .background-color-none > div > span {
	background-color: transparent !important;
}

.bcore-theme .background-color-black > div > span {
	background-color: rgb(var(--black)) !important;
}
.bcore-theme .background-color-black.background-opacity-025 > div > span {
	background-color: rgba(var(--black), 0.25) !important;
}
.bcore-theme .background-color-black.background-opacity-050 > div > span {
	background-color: rgba(var(--black), 0.5) !important;
}
.bcore-theme .background-color-black.background-opacity-075 > div > span {
	background-color: rgba(var(--black), 0.75) !important;
}
.bcore-theme .background-color-black.background-opacity-100 > div > span {
	background-color: rgba(var(--black), 1) !important;
}

.bcore-theme .background-color-white > div > span {
	background-color: rgb(var(--white)) !important;
}
.bcore-theme .background-color-white.background-opacity-025 > div > span {
	background-color: rgba(var(--white), 0.25) !important;
}
.bcore-theme .background-color-white.background-opacity-050 > div > span {
	background-color: rgba(var(--white), 0.5) !important;
}
.bcore-theme .background-color-white.background-opacity-075 > div > span {
	background-color: rgba(var(--white), 0.75) !important;
}
.bcore-theme .background-color-white.background-opacity-100 > div > span {
	background-color: rgba(var(--white), 1) !important;
}

.bcore-theme .background-color-red > div > span {
	background-color: rgb(var(--red)) !important;
}
.bcore-theme .background-color-red.background-opacity-025 > div > span {
	background-color: rgba(var(--red), 0.25) !important;
}
.bcore-theme .background-color-red.background-opacity-050 > div > span {
	background-color: rgba(var(--red), 0.5) !important;
}
.bcore-theme .background-color-red.background-opacity-075 > div > span {
	background-color: rgba(var(--red), 0.75) !important;
}
.bcore-theme .background-color-red.background-opacity-100 > div > span {
	background-color: rgba(var(--red), 1) !important;
}

.bcore-theme .background-color-purple > div > span {
	background-color: rgb(var(--purple)) !important;
}
.bcore-theme .background-color-purple.background-opacity-025 > div > span {
	background-color: rgba(var(--purple), 0.25) !important;
}
.bcore-theme .background-color-purple.background-opacity-050 > div > span {
	background-color: rgba(var(--purple), 0.5) !important;
}
.bcore-theme .background-color-purple.background-opacity-075 > div > span {
	background-color: rgba(var(--purple), 0.75) !important;
}
.bcore-theme .background-color-purple.background-opacity-100 > div > span {
	background-color: rgba(var(--purple), 1) !important;
}

.bcore-theme .background-color-blue > div > span {
	background-color: rgb(var(--blue)) !important;
}
.bcore-theme .background-color-blue.background-opacity-025 > div > span {
	background-color: rgba(var(--blue), 0.25) !important;
}
.bcore-theme .background-color-blue.background-opacity-050 > div > span {
	background-color: rgba(var(--blue), 0.5) !important;
}
.bcore-theme .background-color-blue.background-opacity-075 > div > span {
	background-color: rgba(var(--blue), 0.75) !important;
}
.bcore-theme .background-color-blue.background-opacity-100 > div > span {
	background-color: rgba(var(--blue), 1) !important;
}

.bcore-theme .background-color-green > div > span {
	background-color: rgb(var(--green)) !important;
}
.bcore-theme .background-color-green.background-opacity-025 > div > span {
	background-color: rgba(var(--green), 0.25) !important;
}
.bcore-theme .background-color-green.background-opacity-050 > div > span {
	background-color: rgba(var(--green), 0.5) !important;
}
.bcore-theme .background-color-green.background-opacity-075 > div > span {
	background-color: rgba(var(--green), 0.75) !important;
}
.bcore-theme .background-color-green.background-opacity-100 > div > span {
	background-color: rgba(var(--green), 1) !important;
}

.bcore-theme .background-color-yellow > div > span {
	background-color: rgb(var(--yellow)) !important;
}
.bcore-theme .background-color-yellow.background-opacity-025 > div > span {
	background-color: rgba(var(--yellow), 0.25) !important;
}
.bcore-theme .background-color-yellow.background-opacity-050 > div > span {
	background-color: rgba(var(--yellow), 0.5) !important;
}
.bcore-theme .background-color-yellow.background-opacity-075 > div > span {
	background-color: rgba(var(--yellow), 0.75) !important;
}
.bcore-theme .background-color-yellow.background-opacity-100 > div > span {
	background-color: rgba(var(--yellow), 1) !important;
}

.bcore-theme .background-color-orange > div > span {
	background-color: rgb(var(--orange)) !important;
}
.bcore-theme .background-color-orange.background-opacity-025 > div > span {
	background-color: rgba(var(--orange), 0.25) !important;
}
.bcore-theme .background-color-orange.background-opacity-050 > div > span {
	background-color: rgba(var(--orange), 0.5) !important;
}
.bcore-theme .background-color-orange.background-opacity-075 > div > span {
	background-color: rgba(var(--orange), 0.75) !important;
}
.bcore-theme .background-color-orange.background-opacity-100 > div > span {
	background-color: rgba(var(--orange), 1) !important;
}

.bcore-theme .background-color-magenta > div > span {
	background-color: rgb(var(--magenta)) !important;
}
.bcore-theme .background-color-magenta.background-opacity-025 > div > span {
	background-color: rgba(var(--magenta), 0.25) !important;
}
.bcore-theme .background-color-magenta.background-opacity-050 > div > span {
	background-color: rgba(var(--magenta), 0.5) !important;
}
.bcore-theme .background-color-magenta.background-opacity-075 > div > span {
	background-color: rgba(var(--magenta), 0.75) !important;
}
.bcore-theme .background-color-magenta.background-opacity-100 > div > span {
	background-color: rgba(var(--magenta), 1) !important;
}

.bcore-theme .background-color-cyan > div > span {
	background-color: rgb(var(--cyan)) !important;
}
.bcore-theme .background-color-cyan.background-opacity-025 > div > span {
	background-color: rgba(var(--cyan), 0.25) !important;
}
.bcore-theme .background-color-cyan.background-opacity-050 > div > span {
	background-color: rgba(var(--cyan), 0.5) !important;
}
.bcore-theme .background-color-cyan.background-opacity-075 > div > span {
	background-color: rgba(var(--cyan), 0.75) !important;
}
.bcore-theme .background-color-cyan.background-opacity-100 > div > span {
	background-color: rgba(var(--cyan), 1) !important;
}

/* BorderColor */
.bcore-theme .border-color-none {
	border-color: transparent !important;
}

.bcore-theme .border-color-black > div > span {
	border-color: rgb(var(--black)) !important;
}
.bcore-theme .border-color-black.border-opacity-025 > div > span {
	border-color: rgba(var(--black), 0.25) !important;
}
.bcore-theme .border-color-black.border-opacity-050 > div > span {
	border-color: rgba(var(--black), 0.5) !important;
}
.bcore-theme .border-color-black.border-opacity-075 > div > span {
	border-color: rgba(var(--black), 0.75) !important;
}
.bcore-theme .border-color-black.border-opacity-100 > div > span {
	border-color: rgba(var(--black), 1) !important;
}

.bcore-theme .border-color-white > div > span {
	border-color: rgb(var(--white)) !important;
}
.bcore-theme .border-color-white.border-opacity-025 > div > span {
	border-color: rgba(var(--white), 0.25) !important;
}
.bcore-theme .border-color-white.border-opacity-050 > div > span {
	border-color: rgba(var(--white), 0.5) !important;
}
.bcore-theme .border-color-white.border-opacity-075 > div > span {
	border-color: rgba(var(--white), 0.75) !important;
}
.bcore-theme .border-color-white.border-opacity-100 > div > span {
	border-color: rgba(var(--white), 1) !important;
}

.bcore-theme .border-color-red > div > span {
	border-color: rgb(var(--red)) !important;
}
.bcore-theme .border-color-red.border-opacity-025 > div > span {
	border-color: rgba(var(--red), 0.25) !important;
}
.bcore-theme .border-color-red.border-opacity-050 > div > span {
	border-color: rgba(var(--red), 0.5) !important;
}
.bcore-theme .border-color-red.border-opacity-075 > div > span {
	border-color: rgba(var(--red), 0.75) !important;
}
.bcore-theme .border-color-red.border-opacity-100 > div > span {
	border-color: rgba(var(--red), 1) !important;
}

.bcore-theme .border-color-purple > div > span {
	border-color: rgb(var(--purple)) !important;
}
.bcore-theme .border-color-purple.border-opacity-025 > div > span {
	border-color: rgba(var(--purple), 0.25) !important;
}
.bcore-theme .border-color-purple.border-opacity-050 > div > span {
	border-color: rgba(var(--purple), 0.5) !important;
}
.bcore-theme .border-color-purple.border-opacity-075 > div > span {
	border-color: rgba(var(--purple), 0.75) !important;
}
.bcore-theme .border-color-purple.border-opacity-100 > div > span {
	border-color: rgba(var(--purple), 1) !important;
}

.bcore-theme .border-color-blue > div > span {
	border-color: rgb(var(--blue)) !important;
}
.bcore-theme .border-color-blue.border-opacity-025 > div > span {
	border-color: rgba(var(--blue), 0.25) !important;
}
.bcore-theme .border-color-blue.border-opacity-050 > div > span {
	border-color: rgba(var(--blue), 0.5) !important;
}
.bcore-theme .border-color-blue.border-opacity-075 > div > span {
	border-color: rgba(var(--blue), 0.75) !important;
}
.bcore-theme .border-color-blue.border-opacity-100 > div > span {
	border-color: rgba(var(--blue), 1) !important;
}

.bcore-theme .border-color-green > div > span {
	border-color: rgb(var(--green)) !important;
}
.bcore-theme .border-color-green.border-opacity-025 > div > span {
	border-color: rgba(var(--green), 0.25) !important;
}
.bcore-theme .border-color-green.border-opacity-050 > div > span {
	border-color: rgba(var(--green), 0.5) !important;
}
.bcore-theme .border-color-green.border-opacity-075 > div > span {
	border-color: rgba(var(--green), 0.75) !important;
}
.bcore-theme .border-color-green.border-opacity-100 > div > span {
	border-color: rgba(var(--green), 1) !important;
}

.bcore-theme .border-color-yellow > div > span {
	border-color: rgb(var(--yellow)) !important;
}
.bcore-theme .border-color-yellow.border-opacity-025 > div > span {
	border-color: rgba(var(--yellow), 0.25) !important;
}
.bcore-theme .border-color-yellow.border-opacity-050 > div > span {
	border-color: rgba(var(--yellow), 0.5) !important;
}
.bcore-theme .border-color-yellow.border-opacity-075 > div > span {
	border-color: rgba(var(--yellow), 0.75) !important;
}
.bcore-theme .border-color-yellow.border-opacity-100 > div > span {
	border-color: rgba(var(--yellow), 1) !important;
}

.bcore-theme .border-color-orange > div > span {
	border-color: rgb(var(--orange)) !important;
}
.bcore-theme .border-color-orange.border-opacity-025 > div > span {
	border-color: rgba(var(--orange), 0.25) !important;
}
.bcore-theme .border-color-orange.border-opacity-050 > div > span {
	border-color: rgba(var(--orange), 0.5) !important;
}
.bcore-theme .border-color-orange.border-opacity-075 > div > span {
	border-color: rgba(var(--orange), 0.75) !important;
}
.bcore-theme .border-color-orange.border-opacity-100 > div > span {
	border-color: rgba(var(--orange), 1) !important;
}

.bcore-theme .border-color-magenta > div > span {
	border-color: rgb(var(--magenta)) !important;
}
.bcore-theme .border-color-magenta.border-opacity-025 > div > span {
	border-color: rgba(var(--magenta), 0.25) !important;
}
.bcore-theme .border-color-magenta.border-opacity-050 > div > span {
	border-color: rgba(var(--magenta), 0.5) !important;
}
.bcore-theme .border-color-magenta.border-opacity-075 > div > span {
	border-color: rgba(var(--magenta), 0.75) !important;
}
.bcore-theme .border-color-magenta.border-opacity-100 > div > span {
	border-color: rgba(var(--magenta), 1) !important;
}

.bcore-theme .border-color-cyan > div > span {
	border-color: rgb(var(--cyan)) !important;
}
.bcore-theme .border-color-cyan.border-opacity-025 > div > span {
	border-color: rgba(var(--cyan), 0.25) !important;
}
.bcore-theme .border-color-cyan.border-opacity-050 > div > span {
	border-color: rgba(var(--cyan), 0.5) !important;
}
.bcore-theme .border-color-cyan.border-opacity-075 > div > span {
	border-color: rgba(var(--cyan), 0.75) !important;
}
.bcore-theme .border-color-cyan.border-opacity-100 > div > span {
	border-color: rgba(var(--cyan), 1) !important;
}

.shaka-text-container div span {
	background: none !important;
}
